@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  @include paddings;
  display: flex;
  flex-direction: column;

  @media (min-width: $large-screen) {
    flex-direction: row;

    &> div {
      width: 50%;
    }
  }
}

.Header {
  h2 {
    @include extra-large-title-font;

    color: $orange;
    margin: $spacing-medium 0 $spacing-large;
  }
}

.ContactInfo {
  @include normal-title-font;

  color: $orange;
}

.LeftSide {
  @media (min-width: $large-screen) {
    padding: 0 $spacing-extra-large;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
