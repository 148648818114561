@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Link {
  position: relative;
  cursor: pointer;
}



@mixin underline {
  content: "";
  position: absolute;
  bottom: 0.07em;
  left: 0.1em;
  right: 0.1em;
  height: 0.07em;
  background-color: currentColor;

  @media (min-width: $large-screen) {
    height: 0.05em;
  }
}

.Link::after {
  @include underline;

  transition: transform 0.5s ease-out;
}

.NoPasiveUnderline::after {
  opacity: 0;
}

.Link:hover::after {
  opacity: 1;
  transform: scaleX(1.1);
}

.Active:after {
  @include underline;

  opacity: 1;
  transform: scaleX(1.1);
}
