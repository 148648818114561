@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  padding: $spacing-medium $spacing-large;

  @media (min-width: $large-screen) {
    padding: $spacing-large * 1.5 $spacing-extra-large * 1.5;
    min-width: 800px;
  }
}

.Header {
  display: flex;
  justify-content: space-between;

  h4 {
    @include normal-title-font;

    color: $orange;
  }
}
