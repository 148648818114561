@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Page {
  width: 100%;
  position: relative;
  z-index: $first-layer;
  overflow: hidden;

  * {
    z-index: $first-layer;
  }
}

.BorderTop {
  border-top: 1px solid $sand;
}

.BorderBottom {
  border-bottom: 1px solid $sand;
}

.SoloPage {
  height: 100vh;
}

.FullHeight {
  @media (min-width: $large-screen) {
    height: 100vh;
  }
}

.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
