@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: $spacing-large $spacing-large;

  @media screen and (min-width: $large-screen) {
    padding: $spacing-large $spacing-extra-large * 2;
  }

  h1 {
    @include normal-title-font;
  }

  blockquote {
    @include quote;
    
    margin: 0;

    font-style: italic;
  }
  
  blockquote:before {
    content: "“"; /* Add an opening quote */
    font-size: 3rem;
    position: relative;
    top: 10px;
  }
  
  blockquote:after {
    content: "”"; /* Add a closing quote */
    font-size: 3rem;
    position: relative;
    top: 10px;
  }

  .Divider {
    @include border(top);
    
    width: 70%;
    background-color: $lightGreen;
    margin: $spacing-medium 0;
    
    @media screen and (min-width: $large-screen) {
      margin: $spacing-large 0;
    }

  }
}
