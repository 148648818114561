@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Form {
  padding: $spacing-large 0 0;
  height: 100%;

  form {
    height: 100%;
  }
}

.FormContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Item {
  margin-top: $spacing-small;
}

.Actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
