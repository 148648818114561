@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.PatternImage {
  height: fit-content;
  max-width: 100%;
}

.Pattern {
  padding: 0 $spacing-medium $spacing-medium;

  @media (min-width: $large-screen) {
  }
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: auto; /* Adjust row height as needed */
  position: relative;

  .Row {
    display: flex;
    justify-content: center;
  }

  .Column {
    width: 75px;
    svg: {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
