@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  min-width: 400px;
  margin: $spacing-large 0;

  padding: 0 $spacing-large;

  .Link {
    @include normal-title-font;
    
    color: $orange;
  }
}
