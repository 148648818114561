@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Footer {
  width: 100%;
}

.Container {
  @include border(top);

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: $spacing-medium;
  padding: $spacing-medium;

  font-size: $xxxs-font-size;

  background-color: $cream;
  color: $dark-gray;

  flex-direction: column;

  @media screen and (min-width: $small-screen) {
    flex-direction: row;
  }
}
