@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Text {
  display: inline-block;
}

.Spacing--small {
  margin-top: $spacing-small;
}

.Spacing--medium {
  margin-top: $spacing-medium;
}

.Spacing--large {
  margin-top: $spacing-large;
}