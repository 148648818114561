@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: relative;

  z-index: $first-layer;
}

.BackgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; // Ensure it is behind the content
}
