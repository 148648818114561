@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Size__extra-small {
  width: $extra-small-logo-size;
}

.Size__small {
  width: $small-logo-size;
}

.Size__medium {
  width: $medium-logo-size;
}

.Size__large {
  width: $large-logo-size;
}

.Size__extra-large {
  width: $extra-large-logo-size;
}

.Size__headline {
  width: $extra-large-logo-size * 1.5;

  @media screen and (min-width: $medium-screen) {
    width: $extra-large-logo-size * 1.3;
  }

  @media screen and (min-width: $large-screen) {
    width: $extra-large-logo-size * 2
  }

  @media screen and (min-width: $extra-large-screen) {
    width: $extra-large-logo-size * 3;
  }
}

.Responsive {
  width: $small-logo-size;

  @media screen and (min-width: $medium-screen) {
    width: $medium-logo-size;
  }
}

.Logo {
  height: auto;
  display: flex;
  align-items: center;
}