@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Product {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;

  padding: $spacing-large $spacing-medium * 1.5;

  background-color: none;
  
  @media screen and (min-width: $large-screen) {
    max-width: 365px;
  }
}

.ProductTitle {
  align-items: center;
  display: flex;

  @media screen and (min-width: $large-screen) {
    min-height: $spacing-large * 4;
  }

  h2 {
    @include extra-large-title-font;

    @media screen and (min-width: $large-screen) {
      @include large-title-font;
    }
  }
}

.ProductDivider {
  margin-top: $spacing-medium;

  height: 1px;
  background-color: alpha-color($white, 0.6);

  width: 60%;

  @media screen and (min-width: $large-screen) {
    width: 75%;
  }
}

.ProductDescription {
  @include normal-text-font;
  margin-top: $spacing-medium;

  line-height: $spacing-medium * 1.3;
  letter-spacing: 0.5px;
  color: $white;

  max-width: 500px;

  @media screen and (min-width: $large-screen) {
    max-width: 280px;
  }
}

.ProductActions {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-medium;
  align-items: space-between;
  gap: $spacing-small;
  width: 100%;

  @media screen and (min-width: $medium-screen) {
    flex-direction: row;
    width: auto;
  }

  @media screen and (min-width: $large-screen) {
    align-items: center;
    width: 100%;
  }
}

.ProductFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}
