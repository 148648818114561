@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: relative;
  width: 100%;

  @media screen and (min-width: $large-screen) {
    height: 100vh;
  }
}

.WhiteOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);

  @media screen and (min-width: $small-screen) {
    bottom: 0;
    height: 80%;
  }

  @media screen and (min-width: $large-screen) {
    top: 0;
    left: 20%;
    height: 100%;
  }
}

.Content {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 5;
  justify-content: center;

  flex-direction: column;

  @media screen and (min-width: $small-screen) {
    padding: $spacing-large 0 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 0 2 * $spacing-medium;

    flex-direction: row;
  }

  @media screen and (min-width: $extra-large-screen) {
    padding: 0 2.5 * $spacing-large;
  }
}

.LeftSide {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: $large-screen) {
    align-items: center;

    flex: 1;
  }
}

.RightSide {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  gap: $spacing-medium;

  padding: $spacing-large 0;

  @media screen and (min-width: $medium-screen) {
    gap: $spacing-medium;
    padding-top: $spacing-medium;
  }

  @media screen and (min-width: $large-screen) {
    gap: $spacing-large;

    flex: 2;
  }

  @media screen and (min-width: $extra-large-screen) {
    gap: $spacing-extra-large;
  }
}

.HeroImage {
  height: auto;
  width: 100%;

  @media screen and (min-width: $small-screen) {
    width: 400px;
  }

  @media screen and (min-width: $medium-screen) {
    width: 450px;
  }

  @media screen and (min-width: $large-screen) {
    width: 500px;
  }
}

.Headline {
  @include headline-font;
  
  span {
    display: block;
  }
}
