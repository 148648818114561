@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: inline-block;
  cursor: pointer;
}

.Line {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;

  @media (min-width: $large-screen) {
    width: 40px;
  }
}

/* Rotate first bar */
.Open .Line:nth-child(1) {
  transform: translate(0, 4.5px) rotate(-45deg);
}

/* Rotate last bar */
.Open .Line:nth-child(2) {
  transform: translate(0, -4.5px) rotate(45deg);
}

.Color_orange {
  background-color: $orange;
}