@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  color: $darkGreen;
  margin: $spacing-large 0;

  display: flex;
  align-items: center;
}

.Label {
  @include normal-text-font;

  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    cursor: pointer;
    left: -20px;
    z-index: 1;
    opacity: 0;
    height: 30px;
    width: 50px;
  }

  .Checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $cream;
    border: $sand-border;
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 8px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .Checkmark:after {
    display: block;
  }

  & input:checked ~ .Checkmark {
    background-color: $orange;
  }
}

.Error {
  @include label-font;

  padding-top: $spacing-extra-small;
  color: $orange;
}
