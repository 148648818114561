@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;
  padding: $spacing-extra-large * 0.8 0;
}

.Title {
  h1 {
    @include extra-large-title-font;

    padding: $spacing-medium $spacing-large;
    text-align: center;
    
    @media screen and (min-width: $large-screen) {
      text-align: right;

      padding: $spacing-medium $spacing-extra-large * 2;
    }
  }

  &::before {
    @include border(top);
    
    display: block;
    position: absolute;
    content: "";
    right: 0;
    width: 20%;
    height: 1px;
  }

  &::after {
    @include border(bottom);
    
    display: block;
    position: absolute;
    content: "";
    right: left;
    width: 85%;
    height: 1px;
  }
}

.Products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-large;
  overflow-x: auto;
  padding: $spacing-extra-large * 0.8 0 0;

  @media screen and (min-width: $medium-screen) {
    padding: $spacing-extra-large * 0.8 $spacing-large 0;
  }

  @media  screen and (min-width: $large-screen) {
    flex-direction: row;
  }
}
