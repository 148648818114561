@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: $small-screen) {
    padding-bottom: $spacing-large * 3;
  }

  @media screen and (min-width: $large-screen) {
    padding-bottom: $spacing-large * 5;
  }
}

.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: left;
  z-index: $background-layer;

  @media screen and (min-width: $large-screen) {
    width: 38%;
    height: 90%;
  }
}

.Line {
  position: absolute;
  border-color: $darkGreen !important;
  z-index: $background-layer;

  &__bottom {
    @include border(bottom);
    left: 0;

    @media screen and (min-width: $medium-screen) {
      bottom: 5%;
      width: 80%;
    }

    @media screen and (min-width: $large-screen) {
      bottom: 15%;
      width: 80%;
    }
  }

  &__left {
    @include border(left);
    top: 0;

    @media screen and (min-width: $medium-screen) {
      left: 2.5%;
      height: 98%;
    }

    @media screen and (min-width: $large-screen) {
      left: 5%;
      height: 95%;
    }
  }
}

.Content {
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  align-items: center;
  gap: $spacing-large;

  @media screen and (min-width: $medium-screen) {
    margin-left: 5%;
    margin-top: 2.5%;
  }

  @media screen and (min-width: $large-screen) {
    gap: 0;
    align-items: inherit;
    flex-direction: row;
    margin-left: 9%;
    margin-top: 2.5%;
  }
}

.Image {
  width: 450px;
  height: 100%;

  @media screen and (min-width: $large-screen) {
    width: 450px;
    height: 100%;
  }
}

.Text {
  @include normal-text-font;

  padding: 0 $spacing-large;

  @media screen and (min-width: $large-screen) {
    padding: 0 $spacing-extra-large;
  }

  @media screen and (min-width: $extra-large-screen) {
    margin-left: 5%;
    padding-right: $spacing-large * 10;
  }
}

.WhiteOverlay {
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: $background-layer + 1;
  background: rgba(255, 255, 255, 0.5);

  @media screen and (min-width: $large-screen) {
    display: none;
  }
}
